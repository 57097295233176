import {CSSProperties, FC} from 'react'

import "../../../Styles/colors.scss"
import "../../../Styles/fonts.scss"
import { defaultInputClasses,  defaultInputStyles } from '../Input-Config';

interface ButtonProps{
    onClick: () => void;
    children?: JSX.Element | String;
    style?:CSSProperties;
    className?:string;
}

const Button:FC<ButtonProps> = ({onClick,children,style,className}) => {
  return (
    <button className={`
    b-0 
    cursor-pointer 
    border-box 
    `
    + defaultInputClasses
    + className
  } style={{
    color: "black",
    fontWeight: 400,
    ...defaultInputStyles,
    ...style,
  }}
  onClick={onClick}>{children}</button>
  )
}

export default Button