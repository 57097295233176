import React from "react"

export const defaultInputClasses = `
    Montserrat-Light
`

export const defaultInputStyles: React.CSSProperties = {
    padding: "13px"
}

//Font Size de 1vw?