//Defaults
import { CSSProperties, FC} from 'react'

interface TextAreaProps{
    placeholder:string;
    children?:JSX.Element;
    style?:CSSProperties;
    className?:string;
    type?:string;
    rows?:number;
    cols?:number;
    resize?: boolean;
    name: string;
}


const TextArea:FC<TextAreaProps> = ({placeholder,className,style,type,rows,cols,resize,name}) => <textarea 
className={`
    b-0 
    bg-primary 
    color-primary 
    Montserrat-Light
    ${className}`} 
style={{
    padding:"13px", 
    backgroundColor:"#E8E8E8", 
    color:"#141414",
    resize: resize ? "vertical" : "none",
    minHeight: "2em",
    ...style}} 
name={name}
id="" 
placeholder={placeholder}
rows={rows ?? 4} 
    cols={cols ?? 50}
/>

export default TextArea