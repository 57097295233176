import React, { FC } from 'react'

import { useState } from 'react'
import { Link } from 'react-router-dom';
import HamburguerIcon from "./HamburguerIcon"

import "./MobilNavbar.scss"

interface NavbarItemData { 
  title: string;
  route: string;
  external: boolean;
}

interface NavbarButtonProps {
  children: JSX.Element | String;
  route: any; //TODO: Add type
  margin?: boolean;
  external?: boolean;
  delay: number
}

// const NavbarMobilButton: FC<NavbarButtonProps> = ({ route, children, margin = true, external, delay }) => {
//   return <div style={{pointerEvents:"all", padding:"15px 60px"}} className="active">
//   {
//     (external ?? false)
//     ? <a
//       href={route}
//       className="no-decoration"
//       style={{ cursor: "pointer", }}
//     >
//       <li className="appear" style={{ "--appear-delay": delay + "ms", fontSize: "2em", color: 'black' } as any}>{children}</li>
//     </a>
//     : <Link
//       to={route}
//       className="no-decoration"
//       style={{ cursor: "pointer", }}
//     >
//       <li className="appear" style={{ "--appear-delay": delay + "ms", fontSize: "2em", color: 'black' } as any}>{children}</li>

//     </Link>
//     }
//   </div>
    
// }


const NavbarMobilButton: FC<NavbarButtonProps> = ({ route, children, margin = true, external, delay }) => {
  return <div style={{pointerEvents:"all", padding:"15px 60px"}} className="active">
    <a
      href={route}
      className="no-decoration"
      style={{ cursor: "pointer", }}
    >
      <li className="appear" style={{ "--appear-delay": delay + "ms", fontSize: "2em", color: 'black' } as any}>{children}</li>
    </a>
    
  </div>
    
}

function NavbarMobil(props: any) {
  const navbarData: [NavbarItemData] = props.navbarData;
  const [open, setOpen] = useState(false);
  return (
      open
      ? <>
        <div className='abs' style={{
          background: "white", top: 0, height: "100vmax", width: "100vmax", borderRadius: "50%", transform: "translate(50vw,-50vh)scale(3)"
          ,transition:"ease-out 200ms"
        }}></div>
        {/* <h1 className='rel cursor-pointer noselect' style={{ color: "black", fontSize: "30px",transition:"ease-in-out 300ms", transform:"rotate(45deg)scale(.9)", transformOrigin:"center", textAlign:"center"  }} onClick={() => setOpen(false)}>
          +
        </h1> */}
        <HamburguerIcon open={true} onClick={() => setOpen(false)} fill={"black"}></HamburguerIcon>

        <div className='abs w-screen h-screen z-10 flex-col' style={{ pointerEvents: "none", gap:"1.5em", paddingTop:"100px"}}>
          {navbarData.map((navitem, index) => 
            <NavbarMobilButton route={navitem.route} external={navitem.external} key={navitem.title} delay={(100 * index) + 150}>
              {navitem.title}
            </NavbarMobilButton>
          )}
        </div>
      </>
      : <>
      <div className='abs' style={{
        background: "white", top: 0,  height: "100vmax", width: "100vmax", borderRadius: "50%", transform: "translate(50vw,-50vh)scale(0)"
        ,transition:"ease-out 300ms"
      }}></div>
        {/* <h1 className='cursor-pointer noselect' style={{ color: "white", fontSize: "30px",transition:"ease-in-out 200ms",transformOrigin:"center",transform:"scale(0.7)", textAlign:"center" }} onClick={() => setOpen(true)}>☰</h1> */}
        <HamburguerIcon open={false} onClick={() => setOpen(true)} fill={"white"}></HamburguerIcon>
      </>
        
      )
}

export default NavbarMobil