import { FC } from "react";
// import { Link } from "react-router-dom";
import useWindowSize from "../../Hooks/useWindowSize";

import "./Navbar.scss"
import NavbarMobil from "./Mobile/MobilNavbar";
import { data } from "autoprefixer";
import NavbarButton from "./NavbarButton";
import { JsxElement } from "typescript";


import UserIcon from "./UserIcon";
import logo from "../../Assets/images/navLogo.png"




interface NavbarItemData { 
  title: string;
  route: string;
  external: boolean;
  icon?: any;
}


const navbarData:Array<NavbarItemData> = [
  {title: "Blog", route:"https://www.avda.io/coming-soon", external: false},
  {title: "Register", route:"https://www.avda.io/client/register", external: true},
  {title: "Login", route:"https://www.avda.io/client/login", external: true, icon:true},
]


const rootPage = "https://www.avda.io";



function Navbar() {
  //Programatic Media Query
  const size = useWindowSize();
  const mobil = size.width ? (size.width <= 750 ? true : false) : false;
  return (
    <div
      className="evens-none"
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "100px",
        fontSize: "8pt",
        boxSizing: "border-box",
        padding: "3em",
        zIndex: 20,
        // backgroundColor: "green",
      }}
    >
      <div className="logo flex center-both cursor-pointer rel z-2" style={{width:"auto", height:"100%"}}>
        <a href={rootPage}>
          <img src={logo} alt="Logo" className="cursor-pointer rel z-2" width="35px" height="35px" ></img>
        </a>  
      </div>
      { mobil 
        ? <>
          <NavbarMobil navbarData={navbarData}></NavbarMobil>
        </>
        : <div className="flex" style={{ gap: "4em" }}>
          {navbarData.map(navitem =>
            <NavbarButton route={navitem.route} external={navitem.external} key={navitem.title} icon={navitem.icon}>{navitem.title}</NavbarButton>
          )}
      </div>
      }
    </div>
  );
}

export default Navbar;
