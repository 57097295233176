//Defaults
import { useState,CSSProperties, useEffect } from 'react'

//Custom Hooks
import useWindowSize from '../../Hooks/useWindowSize'

//Components
import Navbar from '../../Components/Navbar/Navbar'
import OverlayForm from './OverlayForm/OverlayForm'
import Button from '../../Components/Input/Button/Button'
import AnimatedBackground from './AnimatedBackground/AnimatedBackground'

//Styles
import '../../Styles/utility.scss'

//Graphics
import AvdaLogo from '../../Assets/images/avdaLogo.svg'
// import { useNavigate } from 'react-router-dom'


function Main(props:any) {
    //State
    const [form,setForm] = useState(false);
    const toggleForm = () => setForm(value => !value);
    // const navigate = useNavigate();
    

    //Hack for iphone-safari
    useEffect(() => {
        document.addEventListener('focusout', function(e) { window.scrollTo(0, 0) });
    },[])
    
    // // Allow a back button press on android
    // useEffect(() => {
    //     form ? navigate("../", { replace: true }) : navigate("../", { replace: true });
    // },[form])

    //Programatic Media Query
    const size = useWindowSize();
    const mobil = size.width ? ((size.width <= 750 ) ?  true : false) : false ;
    const big = size.width ? ((size.width >= 1400 ) ?  true : false) : true ;

    //Responsive Programatic Styles
    const imageSize:CSSProperties = {width: big ? "53%" : "50%"};
    const containerPadding:CSSProperties = {padding:mobil ? "3em" : "calc( 10vw + 20px )"}
    const rowSize:CSSProperties = {width:mobil ? "100%" : "50%",}
    const responsiveFont = {
        fontSize:mobil ? "4.5vw" : "1.8vw",
    }
    const responsiveFontsm = {
        fontSize:mobil ? "3vw" : "1.2vw",
        opacity: "70%",
    }

    //Template
    return <>
        <Navbar/>
        <AnimatedBackground></AnimatedBackground>
        <div className="flex-row size-screen border-box overflow-hiden rel Montserrat-Light" style={containerPadding}>
            <div className="flex-col m-0 p-0 h-100 justify-center" style={rowSize}>
                <img src={AvdaLogo} className="mb-20 w-full h-full"  style={imageSize} alt="AVDA Logo"></img>
                <p className={`${mobil ? "w-full" : "w-80"} mb-0 color-primary`} style={responsiveFont}>
                    We're a leading institutional <span className='color-tertiary'><br></br>crypto trading firm </span></p>
                <p className={`${mobil ? "w-full" : "w-80"} mt-4 mb-40 color-primary`} style={responsiveFontsm}>We trade all major coins, altcoins, and derivatives in the most secure exchanges and markets.</p>
                <Button className="w-50" onClick={toggleForm} style={{}/*{fontSize:mobil ? "2.6vw" : "0.8vw",}*/}>Contact</Button>
            </div>
            <OverlayForm form={form} toggleForm={toggleForm}/>
        </div>
    </>;
}


export default Main