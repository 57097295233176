//Defaults
import { Children, CSSProperties, FC} from 'react'

interface TextProps{
    children?:JSX.Element | string;
    style?:CSSProperties;
    className?:string;
    font?: string;
    size?:string;
}


const Text:FC<TextProps> = ({className,style,font, size, children}) => <p 
className={`
    ${className} Montserrat-Light`} 
style={{
    fontFamily:font ?? "Montserrat", 
    fontSize: size ?? "1.5em", 
    color:"#141414", ...style}} 
id="" 
>{children}</p>

export default Text