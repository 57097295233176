//Defaults
import { CSSProperties, FC} from 'react'

interface TextInputProps{
    placeholder:string;
    children?:JSX.Element;
    style?:CSSProperties;
    className?:string;
    type?:string
    name: string;
}


const TextInput:FC<TextInputProps> = ({placeholder,className,style,type,name}) => <input 
className={`
    b-0 
    bg-primary 
    color-primary 
    Montserrat-Light
    ${className}`} 
style={{
    padding:"13px", 
    backgroundColor:"#E8E8E8", 
    color:"#141414", ...style}} 
type={type ?? "text"} 
name={name}
id="" 
placeholder={placeholder}
/>

export default TextInput