import React, {createRef, CSSProperties, FC, useEffect, useState} from 'react'
import "../../../Styles/utility.scss"
import "../../../Styles/colors.scss"
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import useWindowSize from '../../../Hooks/useWindowSize';
import Sketch from "react-p5"

function getCssVariable(variableName: string):string{
  return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim()
}

let particleCanvasSize:CSSProperties = {
  width: "60vw", 
  height: "100vh"
}

const backgroundColor = 0;
const particleColor = "#5384B2";
const wireColor = [62, 140, 190]

const particleOpacity = 1;
const wireWidth = .5;
const wireDistance = 200;
const wireOpacity = 1;
const particleSpeed = .5;
const particleDensity = 1.5;
const particleMinSize = 1;
const particleMaxSize = 3;
const particleInteractivity = {
  onClick: {
    enable: false,
    mode: "push",
  },
  // "onHover": {
  //   "enable": true,
  //   // "mode": "grab",
  //   "parallax": {
  //     "enable": true,
  //     "force": 100, //Pon en 60 para bajar intensidad
  //     "smooth": 5
  //   }
  // },
  onHover: {
    enable: true,
    mode: "repulse",
  },
  resize: true,
}

const particleConfig:any = {
  // background: {color: {value: backgroundColor}},
  fpsLimit: 20,
  fullScreen: false,
  interactivity: {
    events: particleInteractivity,
    modes: {
      push: {quantity: 4,},
      repulse: {
        distance: 100,
        duration: 0.5,
      },
    },
  },
  particles: {
    color: {value: particleColor,},
    links: {
      color: wireColor,
      distance: wireDistance,
      enable: true,
      opacity: wireOpacity,
      width: wireWidth,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {default: "bounce",},
      speed: particleSpeed,
      straight: false,
    },
    number: {density: {enable: true,area: particleDensity,},value: 80,},
    opacity: {value: particleOpacity},
    shape: {type: "circle",},
    size: {value: { min: particleMinSize, max: particleMaxSize },},
  },
  detectRetina: true,
}

const AnimatedBackground = () => {

  //Programatic Media Query
  const size = useWindowSize();
  const [particles, setParticles] = useState([]);
  const mobil = size.width ? ((size.width <= 750) ? true : false) : false;
  
  const particleCanvasRef:any = createRef();

  if (mobil) particleCanvasSize = { ...particleCanvasSize, width: "100vw" };
  else particleCanvasSize = { ...particleCanvasSize, width: "60vw" }


  const particlesInit = async (main:any) => {await loadFull(main);};
  const particlesLoaded:any = () => {};
  const particlesRef = createRef();

  const ParticleFactory = (p5) => class Particle {
    // setting the co-ordinates, radius and the
    // speed of a particle in both the co-ordinates axes.
    x: any
    y: any
    r: any
    xSpeed: any
    ySpeed: any
      constructor(){
        this.x = p5.random(0,p5.width);
        this.y = p5.random(0,p5.height);
        this.r = p5.random(1,8);
        this.xSpeed = p5.random(-particleSpeed,particleSpeed);
        this.ySpeed = p5.random(-particleSpeed,particleSpeed);
      }
    
    // creation of a particle.
      createParticle() {
        p5.noStroke();
        p5.fill(particleColor);
        p5.circle(this.x,this.y,this.r);
      }
    
    // setting the particle in motion.
    moveParticle() {

        if(this.x < 0 || this.x > p5.width)
          this.xSpeed*=-1;
        if(this.y < 0 || this.y > p5.height)
          this.ySpeed *= -1;
      
          let sx = this.xSpeed;
          let sy = this.ySpeed;
          if (p5.dist(this.x, this.y, p5.mouseX, p5.mouseY) < 100) { 
            const norm = p5.createVector(this.x - p5.mouseX, this.y - p5.mouseY).normalize();
            sx +=norm.x * 10
            sy +=norm.y * 10
            // this.x+=norm.x * 40
            // this.y+=norm.y * 40
          }
      
        this.x+=sx;
      this.y += sy;
      }
    
    // this function creates the connections(lines)
    // between particles which are less than a certain distance apart
    joinParticles(particles) {
      const minDist = wireDistance + 20;
        particles.forEach(element =>{
          let dis = p5.dist(this.x,this.y,element.x,element.y);
          if (dis < minDist) {
            p5.stroke(wireColor[0], wireColor[1], wireColor[2], (minDist - dis) * 100 / minDist);
            p5.strokeWeight(2);
            p5.line(this.x,this.y,element.x,element.y);
          }
        });
      }
    }
    
  let canvas: any;
    
  const setup = (p5, canvasRef) => {
    canvas = p5.createCanvas(window.innerWidth, window.innerHeight).parent(canvasRef)
    p5.frameRate(30);
    // particles = []
    for(let i = 0;i<p5.width/20 * particleDensity;i++){
      particles.push(new (ParticleFactory(p5))());
    }
    
  }

  const draw = (p5) => {
    p5.background(backgroundColor);
    for(let i = 0;i<particles.length;i++) {
      particles[i].createParticle();
      particles[i].moveParticle();
      particles[i].joinParticles(particles.slice(i));
    }
  }

  function windowResized(p5) {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    // particles = []
    // for(let i = 0;i<p5.width/20;i++){
    //   particles.push(new (ParticleFactory(p5))());
    // }
  }

  return (
    <div className="abs flex justify-end -z-10 h-screen events-none w-100">
      <div className='rel' style={particleCanvasSize} ref={particleCanvasRef}>
        {/* Overlay */}
        {mobil 
          ? <></>
          : <div className="bg-gradient-right from-bg-primary to-transparent abs size-full w-50"></div>
        }


        <Sketch setup={setup} draw={draw} windowResized={windowResized} style={{transformOrigin:mobil? "" : "left",transform: "scale(1.05)", position: "relative", zIndex:"-10", pointerEvents: "all"}}         id="tsparticles"></Sketch>
      </div>
    </div>
  )
}
export default AnimatedBackground