import { FC, useState, useEffect} from 'react'
import Main from '../Routes/Main/Main'
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import ComingSoon from '../Routes/ComingSoon/CommingSoon';
import Post from '../Routes/Post/Post';

import WebFont from "webfontloader"

const App: FC = _ => {

//Force the page to preload the font
//Longer loading time but no FOUT
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Montserrat']
      }
    });
  }

  )
  return <Main></Main>
// return <BrowserRouter>
//         <Routes>
//                 <Route path="/" element={<Main />} />
//                 <Route path="coming-soon" element={<ComingSoon />} />
//         </Routes>
//     </BrowserRouter>

}

export default App
