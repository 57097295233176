const HamburguerIcon:any = ({ open, onClick, fill} : any) => {
  return <div style={{ width: "1.5em", height: "1.5em", position: "relative" }} onClick={ onClick} className='cursor-pointer'>
      { open ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.318 489.318">
        <g id="Group_2" data-name="Group 2" transform="translate(11500.659 -2607.341)">
          <rect id="Rectangle_17" data-name="Rectangle 17" className="transition-all" width="592" fill={fill} height="100" rx="50" transform="translate(-11429.948 2607.341) rotate(45)" />
          <rect id="Rectangle_20" data-name="Rectangle 20" className="transition-all" width="110" fill={fill} height="100" rx="50" transform="translate(-11311 2802)" />
          <rect id="Rectangle_21" data-name="Rectangle 21" className="transition-all" width="592" fill={fill} height="100" rx="50" transform="translate(-11500.659 3025.948) rotate(-45)" />
        </g>
      </svg>
        : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 592 499">
          <g id="Group_2" data-name="Group 2" transform="translate(11552 -2602)">
            <rect id="Rectangle_20" data-name="Rectangle 20" className="transition-all" width="592" fill={fill} height="100" rx="50" transform="translate(-11552 2802)" />
            <rect id="Rectangle_17" data-name="Rectangle 17" className="transition-all" width="592" fill={fill} height="100" rx="50" transform="translate(-11552 2602)" />
            <rect id="Rectangle_21" data-name="Rectangle 21" className="transition-all" width="592" fill={fill} height="100" rx="50" transform="translate(-11552 3001)" />
          </g>
        </svg>    
    }
  </div>

}

export default HamburguerIcon;
