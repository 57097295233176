//Defaults
import { CSSProperties, FC, useEffect, useState, createRef} from 'react'

//Hooks
import useWindowSize from '../../../Hooks/useWindowSize'


//Styles
import "../../../Styles/utility.scss"
import "../../../Styles/colors.scss"
import Button from '../../../Components/Input/Button/Button'
import TextInput from '../../../Components/Input/TextInput/TextInput'
import Text from '../../../Components/Text/Text'
import TextArea from '../../../Components/Input/TextArea/TextArea'
import Container50 from '../../../Components/Container/Container50'


//React Toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


interface OverlayFormProps{
    form:boolean;
    toggleForm:() => void;
}


const Arrow = ({color}:{color: string}) => {
    return (
      <svg
        className='z-100 cursor-pointer'
        height="33"
        width="15"
        fill="none"
        viewBox="0 0 15 33"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_30_11)">
          <path
            style={{transition:"ease-in-out all 300ms"}}
            d="M2.15015 1.29L11.1501 16.29L2.31015 31.13"
            stroke={color}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
        <defs>
          <clipPath id="clip0_30_11">
            <rect
              style={{transition:"ease-in-out all 300ms"}}
              height="32.41"
              width="14.06"
              fill={color}
              transform="translate(14.0601 32.41) rotate(-180)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  };
  
//Typedefs
interface OverlayFormProps{
    form:boolean;
    toggleForm:() => void;
}

const OverlayForm:FC<OverlayFormProps> = ({form,toggleForm}) => {
    //Animation Integrity
    const [visibility,setVisiblity] = useState<DocumentVisibilityState | undefined>('hidden')
    useEffect(() => {
        if (form) setVisiblity('visible')
        if (!form && visibility=='visible') {
            setVisiblity(undefined)
            setTimeout(() => setVisiblity('hidden'),transitionDuration)
        }
    },[form])

    //Programatic Media Query
    const size = useWindowSize();
    const mobil = size.width ? ((size.width <= 750 ) ?  true : false) : false ;


    //Responsive Programatic Styles
    const transitionDuration = 300;
    const formAnimation:CSSProperties = {
        visibility: visibility,
        transform:form ? "" : mobil ?  `translateY(100vh)` : `translateX(50vw)`,
        transition: `transform ease-in-out ${transitionDuration}ms`,
    };
    const arrowPosition:CSSProperties = {
        left: mobil  ? "calc( 50vw )" : (form ? 'calc( 50vw + 25px )' : 'calc( 100vw - 50px )'),
        transform: mobil
                ? form ? "rotate(90deg)" : "rotate(270deg)"
                : form ? "" : "rotate(180deg)",
        width:"1vw",
        top:mobil ? (form ? '50px' :  'calc(100vh - 50px)' ) : "calc( 50% - 0.5vw )",
        visibility: 'visible',
        zIndex: 110
    };
    const formSize:CSSProperties = {width:mobil?"100%":"50%"};

    //Optic Correction
    const correccionCorreo = (x:string) => {
      return {paddingTop: x,paddingBottom: x}
    }

    //ToasMessages
    const loadingToast = () => {
      toast("Submitting...",{
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })
    }
    const errorToast = (error) => {
      toast.error(error,{
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })
    }
    const successToast = (msg) => {
      toast.success(msg,{
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        })
    }

    //Form Submition
    const formElement: any = createRef();

    async function handleSubmit(e:any): Promise<any> {
      e.preventDefault()

      let name = formElement.current.name.value
      let lastName = formElement.current.lastName.value
      let email = formElement.current.Email.value
      let text = formElement.current.Content.value

      console.log(name,lastName, email, text);

      var formdata = new FormData();
      formdata.append("first-name", name);
      formdata.append("last-name", lastName);
      formdata.append("email", email);
      formdata.append("message", text);
    
      var requestOptions:any = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

      loadingToast()
      const result = await fetch("https://www.avda.io/wp-json/contact-form-7/v1/contact-forms/48/feedback", requestOptions)
      .then(response => response.json())
      console.log(result)
      if (result.status === 'validation_failed'){
        errorToast(result.message);
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
        {
          errorToast("The entered email does not ressemble a valid email")
        }
      } else if (result.status === "mail_sent"){
        successToast(result.message)
      }
  }

  

    //Template
    return <>
    {/* Dinamic Arrow */}
    <div  className='abs cursor-pointer transition-all events-all z-100' onClick={toggleForm} style={arrowPosition}>
        <Arrow color={form ? "black" : "white"}></Arrow>
    </div>
    <div className="size-screen flex-row-reverse border-box abs z-20x events-none" style={{...formAnimation,height:`${size.height}px`}}>


        {/* Form Area */}
        <div className="m-0 p-0 flex-col justify-center items-center bg-secondary events-all h-100" style={{ width: "100%", ...formSize }}>
            <form method='post' className='flex-col' style={{justifyContent:"space-between", minHeight:"50%",gap:"2em", zIndex:10, padding:0, width:mobil ? "calc( 100% - 20% )" : "calc( 100% - 25% )" , transform:mobil?"translateY(20px)":""}}
            ref={formElement} onSubmit={handleSubmit}> 
                <Text className='events-none Montserrat-Light'>Contact Us</Text>
              <div className='flex-row' style={{ justifyContent: "space-between", alignItems: "stretch", marginBottom: "5px", marginTop: "5px", gap: "1em" }}>
                    <TextInput placeholder='Name' className="w-50" name="name"/>
                    <TextInput placeholder='Last name' className="w-50" name="lastName"/>
                </div>
                <TextInput placeholder='Email' style={correccionCorreo("calc( 13px + 1px)")} name="Email"/>
                <TextArea placeholder='Your information here...' rows={4} cols={50} resize={false} name="Content"></TextArea>
            <Button
              className="bg-tertiary color-primary"
              style={{ width: "calc( 50% - 8px )", marginLeft: "auto", color: 'white' }} onClick={() => {}}>
                  Submit
                </Button>
            </form>
        </div>
        <ToastContainer />
    </div>
    </>

}   







export default OverlayForm;