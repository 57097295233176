import { FC } from "react";
import { Link } from "react-router-dom";
import UserIcon from "./UserIcon";

interface NavbarButtonProps {
    children: JSX.Element | String;
    route: any; //Fix
    margin?: boolean;
    external?: boolean;
    icon? : any
  }

// const NavbarButton: FC<NavbarButtonProps> = ({ route, children, margin = true, external, icon }) => {
    
//   let content = <>
//   {icon ? <UserIcon></UserIcon> : <></>}
//   {children}
// </>
//   return (
//       <div className="cursor-pointer events-all btn border-box flex center-both" style={{fontSize: "1.5em"}}>
//         {(external ?? false)
//         ? <a
//         href={route}
//         className="no-decoration color-primary m-10 flex border-box"
//         style={{cursor: "pointer",}}
//         >
//             {content}
//         </a>
//         :  <Link
//           to={route}
//           className="no-decoration color-primary m-10 flex border-box"
//           style={{cursor: "pointer",}}
//           >
//             {content}
//         </Link>
//       }
//       </div>
//     );
// };
  

const NavbarButton: FC<NavbarButtonProps> = ({ route, children, margin = true, external, icon }) => {
    
  let content = <>
  {icon ? <UserIcon></UserIcon> : <></>}
  {children}
</>
  return (
      <div className="cursor-pointer events-all btn border-box flex center-both" style={{fontSize: "1.5em"}}>
        <a
        href={route}
        className="no-decoration color-primary m-10 flex border-box flex center-both"
        style={{cursor: "pointer",gap:10}}
        >
            {content}
        </a>

      </div>
    );
};
  
export default NavbarButton;

